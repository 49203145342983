// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
import 'bootstrap';

import "../stylesheets/application.scss";

require("packs/parallax.min");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../images', true);

$(document).ready(() => {
  if(!location.hash) {
    window.scrollTo(0, 0);
  }

  setTimeout(() => {
    history.replaceState(null, null, ' ');
  }, 1000);

  // Start Animations
  const elementsToExpand  = document.querySelectorAll(".animate__animated:not(.atf)");
  let expansionObserver   = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.intersectionRatio > 0) {
        let animation = entry.target.getAttribute("data-animation");
        entry.target.classList.add(animation);
        expansionObserver.unobserve(entry.target);
      }
    });
  });

  elementsToExpand.forEach((element) => {
    expansionObserver.observe(element);
  });
  
  // Load body image from html div
  var imgBgBody = $("#img-bg-body").data("img-bg-body");

  $("#banner-wrapper").parallax({
    imageSrc: imgBgBody,
    naturalWidth: 1600,
    naturalHeight: 900
  })

  // Start notice
  let myModal = $("#modal-notice");
  myModal.modal('show');

  // Scroll section into view
  let navLinks = document.querySelectorAll('[data-href]');
  navLinks.forEach(link => {
    link.addEventListener('click', function(e) {
      e.preventDefault();
      let target = link.getAttribute('data-href');
      console.log(target);
      document.getElementById(target).scrollIntoView();
    });
  });

  // Trigger Modal for Employees
  let modalItemEmployee             = $("#modal-item-employee");
  let modalItemEmployeeName         = $("#modal-item-employee-name");
  let modalItemEmployeePosition     = $("#modal-item-employee-position");
  let modalItemEmployeeDescription  = $("#modal-item-employee-description");
  let itemEmployee                  = $(".item-employee");

  itemEmployee.on("click", function() {
    let container = $(this);
    let employeeName        = container.data('name');
    let employeePosition    = container.data('position');
    let employeeDescription = container.data('description');

    console.log(`Name: ${employeeName}`);
    console.log(`Position: ${employeePosition}`);
    console.log(`Description: ${employeeDescription}`);

    modalItemEmployeeName.html(employeeName);
    modalItemEmployeePosition.html(employeePosition);
    modalItemEmployeeDescription.html(employeeDescription);

    modalItemEmployee.modal('show');
  })
  
  // Contact form
  let $errors     = $("#errors");
  let $errorList  = $("#error-list");

  let $inputName        = $("#input-name");
  let $inputEmail       = $("#input-email");
  let $inputPhoneNumber = $("#input-phone-number");
  let $inputMessage     = $("#input-message");
  let $btnSubmit        = $("#btn-submit");

  $btnSubmit.on("click", function() {
    var errorMessages = [];

    $errors.hide();
    $errorList.html("");

    var name        = $inputName.val();
    var email       = $inputEmail.val();
    var phoneNumber = $inputPhoneNumber.val();
    var message     = $inputMessage.val();

    if(!name) {
      errorMessages.push("Name required");
    }

    if(!email) {
      errorMessages.push("Email required");
    }

    if(!phoneNumber) {
      errorMessages.push("Phone number required");
    }

    if(!message) {
      errorMessages.push("Message required");
    }

    if(errorMessages.length > 0) {
      $errors.show();

      errorMessages.forEach((e) => {
        $errorList.append(`<li>${e}</li>`); 
      });
    } else {
      $inputName.prop("disabled", true);
      $inputEmail.prop("disabled", true);
      $inputPhoneNumber.prop("disabled", true);
      $inputMessage.prop("disabled", true);
      $btnSubmit.prop("disabled", true);

      var data = JSON.stringify({
        name: name,
        email: email,
        phone_number: phoneNumber,
        message: message
      })

      console.log(data);

      fetch("https://kw0xsgv189.execute-api.ap-southeast-1.amazonaws.com/default/FnMessenger", {
        method: 'POST',
        body: data
      })
      .then(response => response.json())
      .then(d => {
        alert("Thank you for contacting us! We'll get back to you as soon as we can.");

        $inputName.prop("disabled", false);
        $inputEmail.prop("disabled", false);
        $inputPhoneNumber.prop("disabled", false);
        $inputMessage.prop("disabled", false);
        $btnSubmit.prop("disabled", false);

        $inputName.val("");
        $inputEmail.val("");
        $inputPhoneNumber.val("");
        $inputMessage.val("");
      }).catch((err) => {
        console.log(err);

        $inputName.prop("disabled", false);
        $inputEmail.prop("disabled", false);
        $inputPhoneNumber.prop("disabled", false);
        $inputMessage.prop("disabled", false);
        $btnSubmit.prop("disabled", false);
      })
    }
  });
});
